import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function WirelessTesting() {
  return (
    <Layout>
      <SEO
        keywords={[
          `hack guard`,
          `wireless testing`,
          `network testing`,
          `Cybersecurity`,
        ]}
        title="Wireless Penetration Testing"
      />

      <section className="flex flex-col items-center md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <h1 className="p-4 mb-4 border-b-4 border-teal-700 text-bold text-3xl">
            Wireless Penetration Testing
          </h1>
          <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-teal-700">
            Wireless technology is becoming more and more prevalent. its brings
            mobility and convenience to your employees. However, this
            convenience could be used to extend your internal networks to
            potential external attackers within physical range of your
            establishments. We want to find the weakness of your wireless
            technology before an attacker does. We test your networks and
            services to highlight your risk exposure.
          </blockquote>
        </div>
      </section>
    </Layout>
  );
}

export default WirelessTesting;
